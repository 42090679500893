import { useEffect, useState } from 'react'

type Width = number
export const useMobile = (pixels?: Width): { isMobile: boolean } => {
  const limitPixels = pixels || 768
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkWindowWidth = () => {
      const isBelowLimit = window.innerWidth <= limitPixels
      setIsMobile(isBelowLimit)
    }
    window.addEventListener('resize', checkWindowWidth)
    return () => {
      window.removeEventListener('resize', checkWindowWidth)
    }
  }, [])

  return { isMobile }
}
